import axios from "@/plugins/Axios";

const model = "tools";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function paymentTools() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/payment-only`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(record) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, record);
}

function update(id, tool) {
  return axios.patch(`${process.env.VUE_APP_API_URL}/${model}/${id}`, tool);
}

function files(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}/files`);
}

function createFiles(id, file) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/files`,
    file
  );
}

function images(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}/images`);
}

function createImages(id, file) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/images`,
    file
  );
}

function createMainImage(id, file) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/main-image`,
    file
  );
}

export {
  all,
  find,
  remove,
  create,
  update,
  files,
  createFiles,
  images,
  createImages,
  createMainImage,
  paymentTools,
};
