<template>
  <div class="container">
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ selectedTool.name }}
            </h5>
            <button
              id="close-tools-modal"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                class="col-lg-6 mt-3"
                v-for="image in selectedTool.images_tools"
                :key="image.id"
              >
                <img
                  :src="image.image_link"
                  class="img-fluid"
                  alt="Imagen de herramienta"
                />
              </div>
            </div>
            <div class="mt-3">
              <h6>{{ selectedTool.name }}</h6>
              <h6>Categoría: {{ selectedTool.category }}</h6>
              <p class="mt-3">Descripción: {{ selectedTool.description }}</p>
              <p>Contenido: {{ selectedTool.content }}</p>
            </div>
            <c-primary-button @click="buyTool(selectedTool)" class="w-100 mt-4">
              {{
                selectedTool.free
                  ? "Obtener herramienta gratuita"
                  : "Comprar herramienta"
              }}
            </c-primary-button>
          </div>
        </div>
      </div>
    </div>
    <c-card title="Herramientas">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Herramienta</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tool in tools" :key="tool.id" class="align-middle">
            <td>
              <img
                height="50"
                :src="tool.main_image_link"
                alt="Imagen de herramienta"
              />
            </td>
            <td>
              {{ tool.name }}
            </td>
            <td>
              <c-primary-button
                v-if="tool.userHasTool"
                @click="getToolFiles(tool.id)"
              >
                Descargar
              </c-primary-button>
              <c-primary-button
                v-else
                @click="showInModal(tool)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Visualizar
              </c-primary-button>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { toolFiles, tools as userTools } from "@/services/users";
import { all } from "@/services/tools";
import { showNotification } from "@/services/notification";
import CCard from "@/components/CCard.vue";
export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      selectedTool: {},
      userTools: [],
      tools: [],
      purchasedTool: this.$route.query.tool,
    };
  },
  mounted() {
    this.getAllTools();
    if (this.purchasedTool) {
      this.getToolFiles(this.purchasedTool);
    }
  },
  methods: {
    getAllTools() {
      all()
        .then((response) => {
          this.tools = response.data;
          this.getUserTools();
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "No pudimos obtener las herramientas. " + error
          );
        });
    },
    getUserTools() {
      userTools()
        .then((response) => {
          this.userTools = response.data;
          this.mergeTools();
        })
        .catch(() => {
          showNotification(500);
        });
    },
    mergeTools() {
      this.tools.forEach((tool) => {
        const userPaymentTool = this.userTools.find(
          (userTool) => tool.id === userTool.id
        );
        if (userPaymentTool) {
          tool.userHasTool = true;
        }
      });
    },
    showInModal(tool) {
      this.selectedTool = tool;
    },
    getToolFiles(toolId) {
      toolFiles(toolId)
        .then((response) => {
          const files = response.data;
          files.forEach((file) => {
            window.open(file.document);
          });
        })
        .catch((error) => {
          showNotification(
            error.status,
            "danger",
            "No pudimos descargar las herramientas. " + error
          );
        });
    },
    buyTool() {
      this.selectedTool.free
        ? this.getToolFiles(this.selectedTool.id)
        : window.open(this.selectedTool.link);
    },
  },
};
</script>
